import React, { useRef, useState, useEffect } from 'react';
import Carousel from './components/Carousel';
import CarouselT from './components/CarouselTwo';
import { PopupButton } from 'react-calendly';



function App() {
  const formRef = useRef(null);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    phone: '',
    email: '',
    company: '',
    subject: '',
    message: ''
  });

  const homeRef = useRef(null);
 
  const solutionsRef = useRef(null);
  const fundingRef = useRef(null);
  const ourTeamRef = useRef(null);


  const [responseMessage, setResponseMessage] = useState(null);
   const [contactFormerrmess, setContactFormerrmess] = useState(null)
   const [isModalOpen, setIsModalOpen] = useState(false) //T&Cs Modal state
   const [isPrivacyPoModalOpen, setIsPrivacyPolicyModalOpen] = useState(false)

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
   const [isPhilipDropdownOpen, setPhilipDropdownOpen] = useState(false);
   const [isPapaDropdownOpen, setPapaDropdownOpen] = useState(false);
   const [isJamesDropdownOpen, setJamesDropdownOpen] = useState(false);
   const [isNefertitiDropdownOpen, setNefertitiDropdownOpen] = useState(false);
   const [isEvansDropdownOpen, setEvansDropdownOpen] = useState(false);
 
   // Toggle dropdown for each member
  
   const togglePhilipDropdown = () => setPhilipDropdownOpen(!isPhilipDropdownOpen);
   const togglePapaDropdown = () => setPapaDropdownOpen(!isPapaDropdownOpen);
   const toggleJamesDropdown = () => setJamesDropdownOpen(!isJamesDropdownOpen);
   const toggleNefertitiDropdown = () => setNefertitiDropdownOpen(!isNefertitiDropdownOpen);
   const toggleEvansDropdown = () => setEvansDropdownOpen(!isEvansDropdownOpen);
   const dropdownRef = useRef(null)
   const philipDropdownRef = useRef(null);
   const papaDropdownRef = useRef(null);
   const jamesDropdownRef = useRef(null);
   const nefertitiDropdownRef = useRef(null);
   const evansDropdownRef = useRef(null);
   const aboutRef = useRef(null)

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const PPopenModal = () => setIsPrivacyPolicyModalOpen(true)
  const PPcloseModal = () => setIsPrivacyPolicyModalOpen(false)


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
       
      }
      if (philipDropdownRef.current && !philipDropdownRef.current.contains(event.target)) {
        setPhilipDropdownOpen(false);
      }
      if (papaDropdownRef.current && !papaDropdownRef.current.contains(event.target)) {
        setPapaDropdownOpen(false);
      }
      if (jamesDropdownRef.current && !jamesDropdownRef.current.contains(event.target)) {
        setJamesDropdownOpen(false);
      }
      if (nefertitiDropdownRef.current && !nefertitiDropdownRef.current.contains(event.target)) {
        setNefertitiDropdownOpen(false);
      }
      if (evansDropdownRef.current && !evansDropdownRef.current.contains(event.target)) {
        setEvansDropdownOpen(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  // Function to open Calendly popup
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToAboutSection = () => {
    const headerHeight = 180; // Adjust this value according to your header height
    const aboutPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: aboutPosition - headerHeight, // Subtract header height for offset
      behavior: 'smooth',
    });
   
  }

  const scrollToSection = (sectionRef) => {
    const headerHeight = 180; // Adjust this value according to your header height
    if (sectionRef.current) {
        const sectionPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: sectionPosition - headerHeight,
            behavior: 'smooth',
        });
    }
};

  const scrollToForm = () => {
    const headerHeight = 142; // Adjust this value according to your header height
    const formPosition = formRef.current.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: formPosition - headerHeight, // Subtract header height for offset
      behavior: 'smooth',
    });
  };

  const backgroundImages = [
    '/Image Files/2024-09-19_Banner_1-01-01-01edited.png',
    '/Image Files/2024-09-19_Banner_2-01-01.png',

  ]

  const slides = [
    {
      backgroundImage: '/Image Files/Display Board_300-01.png',
      svgImage: '/Image Files/Display Board_300-01.png',
      
    },
    {
      backgroundImage: '/Image Files/2024-09-19_Banner_2-01-01.png',
      svgImage: '/Image Files/EdTech Consulting_3-01.png',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget tristique ligula. Ut vestibulum metus mattis ultricies dapibus. Pellentesque',
      title: ' Text for Slide 2',
      
    },
    
    {
      backgroundImage: '/Image Files/2024-09-19_Banner_2-01-01.png',
      svgImage: '/Image Files/Service Bouqet Banner-02.jpg',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget tristique ligula. Ut vestibulum metus mattis ultricies dapibus. Pellentesque',
      title: ' Text for Slide 3',
    },
    {
    backgroundImage: '/Image Files/2024-09-19_Banner_2-01-01.png',
    svgImage: '/Image Files/STAMP Device-01.png',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget tristique ligula. Ut vestibulum metus mattis ultricies dapibus. Pellentesque',
    title: ' Text for Slide 4',
  },

   
  ];
     
  const features = [
    {
      title: "Turnkey STEAMLab Deployment",
      description:
        "We offer consulting services to basic and secondary schools to deploy advanced STEAMLabs or convert an existing ICT Lab into a modernised facility. Services include: ",
      image: "/Image Files/STEAM-01.png",
      points: ["Lab funiture design and production", "Lab apparatus supply & Teaching aids", "AI/VR/AR Simulation Devices", "STEAM Facilitator Training Services."]
    },
    {
      title: "Integrated Smart Classroom",
      description:
        "Re-modelled classrooms with integrated smart devices to enhance teaching-&-leaming experiences. ISClass is designed to be applicable in pre-school, basic/high schools as well as universities and workplace training centers.",
      image: "/Image Files/Smart Classroom-01.png",
      points: [ "Teacher console with livestreaming",
      "Computer embedded classroom desks",
      "Smart interactive whiteboards &  AI tools" ]
    },
    {
      title: "Schoolwide Network Infrastructure",
      description:
        "SNISignal is a robust reliable networking infrastructure, specifically designed for educational institutions. SNISignal offers a hybrid connectivity, efficient high bandwidth data throughput over LAN/WAN infrastructure, enables stable school-wide virtual learning without internet connectivity.",
      image: "/Image Files/Network with background-01.png",
      points: ["Enterprise grade routers & IoT-switches", "Fibre Optic & LoraWAN Infrastructure", "IoT Severs & Cache-Memory Repositotories"]
    },
    {
      title: "STAMP - School Space Security System",
      description:
        "STAMP is an integrated school environment security systems, a contactless facial recognition and thermal sensor people flow management, including time & attenadnce, register marking and guests/parents visit management.",
      image: "/Image Files/STAMP Device-01.png",
      points: ["Smart entry & exit (access) systems", "Multi-biometric device, facial, palm, finger", "Deep Learning algorithmns to learn identities of students, teachers, parents/guardians, non teaching staff and flow management "]
    },
    {
      title: "SEES - School Energy Efficiency Systems",
      description:
        "Energy efficiency simply means using less energy, reducing greenhouse gas emissions, and lowering your consumption costs. These are the direct benefits our SEES solutions offer to your institution. Deploying people counting & occupancy sensors and controllers, offer to your institution. Deploying people offer to your institution. Deploying people to manage devices usage. We promise upto.",
      image: "/Image Files/SEES-Energy-01.png",
      points: ["Energy consumption analysis reporting", "IoT controllers & sensors for monitoring", "Intelligent lights, ACs, & devices switches"]
    },
    {
      title: "STR - School Tech Ready Consulting",
      description:
        "Our team of experts in both technology and academia are passionate about ensuring all schools & knowledge transfer facilities are up to speed with technologies for effective institutional operations in the delivery of quality education. We offer professional advisory to  schools/universities and training centres on their journey to technology transformation.",
        
      
      image: "/Image Files/ITConsulting-01.png",
      points: ["ICT infrastructure assessment consulting", "iV&V-Independent Verification & Validation", "EdTech Investment funding facilitation"]
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // Validate email
    if (!email) {
      setErrorMessage('Email is required.');
      return;
    }

    try {
      const response = await fetch('http://localhost:8000/subscribe', { // Adjust the URL as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const data = await response.json();
        console.log(data)
        setErrorMessage(data.errors ? data.errors[0].msg : 'Failed to subscribe. Please try again.');
      } else {
        setSuccessMessage('Successfully subscribed to the newsletter!');
      
      }
    } catch (error) {
      setContactFormerrmess('An error occurred. Please try again later.');
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('http://localhost:8000/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        setResponseMessage(result.message);
        setErrorMessage(null);  // Clear any previous error messages
      }
    } catch (error) {
      console.log(error)
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  

  
  
  return (
    <div className=" font-roboto ">
    {/* Header */}
      <div className='bg-gray-300 py-2 sticky top-0 z-[70]' >
      <div className=' max-w-screen-2xl xl:px-20 px-4 mx-auto flex items-center w-full'>
      <div className='my- flex ml-auto'>
      <div className="flex items-center mr-3 border border-gray-300  overflow-hidden">
            <div className="p-1 px-2  bg-[#27348B]">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17.5 17.5L22 22" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
            </div>
            <input 
                type="text" 
                placeholder="Search..." 
                className="p-1  outline-none"
            />
        </div>
            <button onClick={scrollToForm} className='mr-3 border-r-2 pr-3 border-r-[#27348B] flex items-center gap-2 text-[#27348B]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='' fill="none">
<path d="M11.9955 12H12.0045M15.991 12H16M8 12H8.00897" stroke="#141B34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5759 2.40213 14.9412 3.06417 16.2246C3.32379 16.7279 3.40711 17.3096 3.24438 17.8521L2.53365 20.2212C2.3048 20.984 3.01602 21.6952 3.77882 21.4664L6.14794 20.7556C6.69036 20.5929 7.27207 20.6762 7.77536 20.9358C9.05879 21.5979 10.4241 22 12 22Z" stroke="#141B34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Contact Us
            </button> 
           
           <div className='flex items-center gap-2'>
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
<path d="M18.6649 8.33296C18.9053 8.21284 19.1917 8.23059 19.4155 8.37949L22.4155 10.3755C22.6244 10.5146 22.75 10.7489 22.75 10.9999V14.9999C22.75 15.2507 22.6247 15.4849 22.416 15.624L19.416 17.624C19.1918 17.7735 18.9047 17.7912 18.6638 17.6704L16.25 16.4598V9.53921L18.6649 8.33296Z" fill="#141B34"/>
<path d="M4.58455 8.37949C4.80833 8.23059 5.09469 8.21284 5.33515 8.33296L7.75 9.53921V16.4598L5.33622 17.6704C5.09535 17.7912 4.80818 17.7735 4.58397 17.624L1.58397 15.624C1.37533 15.4849 1.25 15.2507 1.25 14.9999V10.9999C1.25 10.7489 1.37557 10.5146 1.58455 10.3755L4.58455 8.37949Z" fill="#141B34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C8.53612 4 6 6.37764 6 9H4C4 4.99494 7.7319 2 12 2C16.2681 2 20 4.99494 20 9H18C18 6.37764 15.4639 4 12 4Z" fill="#141B34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 20V17H20V21C20 21.5523 19.5523 22 19 22H12V20H18Z" fill="#141B34"/>
</svg>
            <PopupButton
      url="https://calendly.com/jyawnk247/1-hour-meeting"
      rootElement={document.getElementById('root')}
      text="Book a Call"
      className="  text-[#27348B]"
    />

</div>
          </div>
      </div>
      </div>
      <div className='bg-[#27348B] w-full border-b-white border border-x-0 border-b-[1px]  text-white sticky top-[50px] z-50  '>
        <div className='lg:py-5 py-3 max-w-screen-2xl xl:px-20 px-4 mx-auto flex items-center w-full'>
       
       <div className=''> <svg xmlns="http://www.w3.org/2000/svg" version="1.0" className=' w-[40px] md:w-[70px] md:h-[70px]  h-[40px' viewBox="0 0 423.000000 414.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,414.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">
<path d="M1870 3996 c-358 -52 -689 -194 -960 -413 -80 -65 -271 -257 -308 -310 l-23 -32 137 -153 c75 -84 138 -155 140 -156 1 -2 33 29 71 69 250 266 591 459 928 525 124 24 341 25 455 1 306 -65 651 -254 969 -531 l74 -64 129 154 128 155 -22 29 c-12 16 -60 65 -107 108 -353 322 -695 515 -1056 598 -100 22 -148 27 -290 30 -107 2 -205 -2 -265 -10z"/>
<path d="M1902 3155 c-214 -39 -384 -119 -555 -263 -69 -59 -187 -188 -187 -206 0 -4 55 -75 123 -160 l123 -153 49 45 c154 138 324 236 475 273 252 63 567 -40 805 -263 57 -52 68 -59 80 -47 34 35 245 294 245 300 0 4 -37 46 -82 94 -192 203 -431 334 -692 380 -106 18 -283 18 -384 0z"/>
<path d="M1983 2335 c-99 -27 -207 -95 -262 -164 l-31 -38 211 -249 c117 -137 213 -250 214 -252 1 -1 93 110 204 249 160 200 199 254 191 268 -16 31 -90 102 -138 132 -102 65 -265 87 -389 54z"/>
<path d="M3525 1993 c-149 -13 -456 -57 -554 -79 -252 -56 -467 -144 -713 -291 l-128 -76 1 -526 c0 -327 4 -517 9 -503 16 39 86 135 127 173 225 211 819 387 1620 479 144 16 267 30 272 30 22 0 -223 416 -366 623 -96 138 -126 173 -80 95 80 -140 348 -618 353 -632 5 -14 -3 -16 -67 -16 -95 0 -330 -16 -539 -35 -496 -47 -992 -226 -1218 -439 -29 -27 -54 -47 -56 -45 -3 2 -6 162 -7 354 l-2 350 109 67 c174 108 406 221 549 268 72 23 279 80 460 126 182 45 317 82 300 82 -16 -1 -48 -3 -70 -5z"/>
<path d="M670 1968 c272 -63 660 -162 755 -193 126 -41 300 -125 498 -240 l137 -80 0 -352 c0 -194 -3 -353 -6 -353 -3 0 -36 26 -72 57 -88 74 -154 116 -276 174 -193 91 -402 157 -661 208 -120 24 -362 46 -660 62 -121 6 -221 12 -221 13 -1 0 62 116 140 256 255 460 257 463 150 310 -86 -122 -163 -247 -281 -454 -90 -157 -101 -182 -86 -188 10 -4 146 -20 303 -37 279 -30 464 -60 745 -121 263 -58 435 -115 640 -216 153 -74 227 -139 300 -260 l35 -58 0 521 0 522 -89 55 c-298 184 -604 296 -946 345 -129 19 -454 52 -479 50 -6 -1 27 -10 74 -21z"/>
<path d="M225 1030 c59 -28 248 -92 670 -225 287 -91 485 -165 642 -240 188 -90 249 -134 470 -341 l121 -114 87 94 c262 286 529 443 1015 596 329 104 790 254 787 256 -4 5 -234 -14 -392 -32 -163 -18 -580 -92 -725 -129 -344 -87 -640 -274 -740 -468 l-34 -64 -28 59 c-34 73 -111 156 -192 207 -319 201 -829 342 -1470 406 -138 14 -255 25 -260 24 -6 0 16 -14 49 -29z"/>
</g>
</svg> </div>
         <div className=' bg-white border-r-[2.5px] h-16 ml-2.5 my-3 mr-2.5' ></div>
          <div className='my-auto'>
            <span className='block text-left text-2xl sm:text-4xl  tracking-wide font-semibold'>HALISY</span>
            <span className='sm:text-2xl'>Edcosystem</span>
          </div>
          <div className='my-auto text-xl ml-auto'>
          <button 
                className='text-white mr-8' 
                onClick={() => scrollToSection(homeRef)}
            >
                Home
            </button>
            <button onClick={scrollToAboutSection} className='text-white mr-8'>About Us</button>
            <button 
                className='text-white mr-8' 
                onClick={() => scrollToSection(solutionsRef)}
            >
                Solutions and Services
            </button>
            <button 
                className='text-white mr-8' 
                onClick={() => scrollToSection(fundingRef)}
            >
                Funding
            </button>

            <button 
                className='text-white' 
                onClick={() => scrollToSection(ourTeamRef)}
            >
                Our Team
            </button>
          </div>
        </div>
      </div>


     

       {/* Hero Image */}
      <div className="relative  w-full  overflow-hidden" ref={homeRef}>
  <img className=" " src="/Image Files/2024-10-15_Display Board_Extended_Blue-01.png" />
 
</div>





    {/* About Us */}
    <div style={{backgroundImage: `url('Image Files/Bg3.jpg') `,  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'}} ref={aboutRef}>
      <div className='max-w-screen-2xl mx-auto px-4 xl:px-20'>
        <div className='flex flex-col w-full   items-center md:items-stretch md:flex-row'>
         
          <div className='bg-white  mb-10 px-6 md:px-7 py-9   bg-opacity-60 p-5 w-full  '>
           


            <div className='flex flex-col  md:flex-row items-center'>         
            <img className='  mr-6 w-[40%] md:w-[35%] lg:w-[25%]' src='/Image Files/SVG Files/Pencil+Academic BooksCap-01.svg' />
        <div className='flex flex-col '>
        <p className='lg:text-[37px] text-2xl  text-[#333333] leading-[1.1] tracking-[-1%]'>Welcome ... <span className='block '>HALISY Edcosystem</span></p>
            
           <p className='  leading-[1.5] text-gray-700 tracking-[-0.5%]  mt-6 text-left text-base lg:text-[22px] '> HALISY Edcosystem solutions are “e-teaching & learning” technologies, designed and
developed to support the efforts of education
stakeholders to achieve a realistic digitisation
of “teaching-&-learning” activities in the
classroom, lecture halls, ICT/STEAM laboratories, e-libraries as well as workplace professional skills training.
The Edcosystem applies a comprehensive
range of computer hardware systems, software and application platforms , AI & electronic
resources, smart devices to deliver functional
e-teaching & learning tools. <span className=''>HE deploys
advanced network infrastructure to enable
school-wide off-internet virtual learning.</span>
<br></br>
<br></br>
We combine our wealth of technical expertise and
comprehensive understanding of the
industry to bring excellence in the EdTech value
chain.
We are focused on continuous research, design,
engineering and development of “end-to-end” in
latest trends to facilitate knowledge providers
with all the required electronic tools and
resources they need to deliver effective teaching/training to their learners.
<br></br>
<br></br>
HALISY Edcosystem solutions offer  fully
integrated e-teaching-&-learning environment,
from pre-school, basic and high school through
to the university level, as well as workplace training,
providing effective lifelong learning technological solutions. 
<p className='mt-4 text-base lg:text-[22px] text-blue-900 font-semibold italic'>HALISY Edcosystem; EdTech, as it must be...</p>
</p>
</div>
</div>






            
          </div>
        </div>

        

      </div>






      
      </div>

     

     <div>
      
     </div>

  {/* Solutions and Services */}

      <div className='max-w-screen-2xl mx-auto xl:px-20 px-4 ' ref={solutionsRef}>
        <p className='lg:text-[37px] text-3xl mt-[40px] leading-[1.1] tracking-[-1%]  md:mt-[60px] text-left'>Our Bouquet of  Solutions and Services</p>
        <div className='h-[18px] mt-[5px] border-4 border-x-0 border-t-0 border-b-[#27348B] w-[40px]'></div>
      </div>

      <div className="relative md:block  bg-black  max-w-[1376px] mx-4 lg:mx-auto mt-5  py-5  text-white">
    {/* Overlay */}
    <div className="absolute max-w-[1376px] mx-auto  inset-0 bg-[#27348B] opacity-80"></div>
    <div className="relative flex flex-col md:flex-row z-10  w-full   ">
    <div className=' flex flex-col justify-center w-full md:w-[50%]'>
    
    
    <h2 className='text-3xl px-3 '>Smart Classroom </h2>
    <div className='flex  mt-2 md:mt-0      items-center text-base md:text-lg  px-3  gap-3'>
      <p className=''>WITBoard IFP</p> <div className='bg-white w-0.5 h-[32px] md:h-[18px]'></div>
      <p>Smart Lecture Console</p> <div className='bg-white w-0.5 h-[32px] md:h-[18px]'></div>
      <p>IPC Embedded Desk</p> 
    </div>
   </div>
   <div className='grid grid-cols-3 md:grid-cols-4 xl:flex mt-1 md:mt-auto gap-x-1 gap-y-2  lg:gap-[13px] w-full px-0 md:w-[50%] mx-3 p-3 md:p-0'>
  
  <div className=''>
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 1.png' />
    <p className='mt-1 text-sm font-bold'>Touch Screen</p>
  </div>

  <div>
    <img className='w-[76px]' src='Image Files/Asset 8 - Power Backup.png' />
    <p className='mt-1 text-sm font-bold'>Power Backup</p>
  </div>

  <div className=''>
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 4.png' />
    <p className='mt-1 text-sm font-bold'>Teacher Screen</p>
  </div>

  <div >
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 5.png' />
    <p className='mt-1 text-sm font-bold'>WIFI Ready</p>
  </div>

  <div className=''>
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 9 - Microphone.svg' />
    <p className='mt-1 text-sm font-bold'>Microphone</p>
  </div>

  <div>
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 7.png' />
    <p className='mt-1 mr-1  text-sm font-bold'>Lesson Timer</p>
  </div>

  <div>
    <img className='w-[76px]' src='Image Files/Smart Classroom Elements/Asset 10 - Stereo Audio.svg' />
    <p className='mt-1 text-sm font-bold'>Stereo Audio</p>
  </div>
  
</div>

   </div>
   </div>
   <div className='max-w-screen-2xl xl:px-20 px-4 mx-auto'>
    <div className='bg-gray-100 p-3 justify-center  flex flex-col lg:flex-row '>
    <img className='lg:w-[50%] -mt-2' src='Image Files/Smart Classroom-01 (1).png' />

    <div class="grid grid-cols-1 -ml-2   w-full sm:grid-cols-2 md:grid-cols-3 gap-4 pt-4 md:pt-0 pr-0 pl-0  md:pl-4 p-4">
  
    <div className="relative p-4 py-2 h-64 ">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center  justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative mx-auto object-cover   z-10" src='Image Files/Smart Classroom Elements/Asset 8 1.png' />
  
</div>


<div className="relative  p-4 h-64">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative mx-auto z-10 " src='Image Files/Smart Classroom Elements/Asset 9 1.png' />
</div>

<div className="relative p-4 h-64">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative mx-auto z-10" src='Image Files/Smart Classroom Elements/Asset 10 1.png' />
</div>

<div className="relative p-4 h-64">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative md:w-auto w-[60%] mx-auto z-10" src='Image Files/Asset 7 - WITBoard.png' />
</div>

<div className="relative p-4 h-64">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative mx-auto  z-10" src='Image Files/Smart Classroom Elements/Asset 11 1.png' />
</div>
<div className="relative p-4 h-64">
    {/* Background Color */}
    <div className="absolute inset-0 flex items-center justify-center w-full bg-[#27348B] opacity-15 z-0"></div>
    
    {/* Content */}
    <img className="relative mx-auto z-10" src='Image Files/Smart Classroom Elements/Asset 14 1.png' />
</div>
</div>


 
  
  
  
  
</div>
    </div>

        {/* Services */}
      <div className='py-3 mt-[15px]  max-w-screen-2xl xl:px-20 px-4 mx-auto flex items-center w-full'> 
     
   
     
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
   
      {features.map((feature, index) => (
          <div key={index}    className="rounded overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition-transform transform hover:scale-105 hover:shadow-[0_8px_20px_rgba(0,0,0,0.3)] opacity-90 hover:opacity-100  hover:bg-gray-100 duration-300">
            <img className="w-full" src={feature.image} alt={feature.title} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{feature.title}</div>
              <p className="text-gray-700 text-base text-justify mb-3">{feature.description}</p>
              {feature.points.map((point, pointIndex) => (
                <div className='flex flex-row '>
              <div className='w-4 h-4 mr-2 mb-3 flex-shrink-0 bg-gray-800'></div><p className=' -mt-0.5' >{point}</p>
              </div>
              ))}
            </div>
          </div>
        ))}

</div>
</div>

<div>
  <div className=' mt-[60px] overflow-hidden  '>
  
  
    

  </div>
</div>

  {/* Funding and Mission */}

<div ref={fundingRef} className='pt-7 ' style={{backgroundImage: `url('Image Files/Bg3.jpg') `,  backgroundPosition: 'center',
  backgroundSize: 'cover',
  marginTop: '60px',

  }}>
 <div className='max-w-screen-2xl bg- mx-auto px-4 mt-[0px] xl:px-20'>
   <div className='flex flex-col  gap-4'>
   <div className='w-full  relative'>
     
      <div className='w-full  xl:h-[620px] ' style={{background: 'linear-gradient(-237.75deg, rgba(110, 198, 217, 1) 13.54%, rgba(88, 184, 208, 1) 21.05%, rgba(33, 149, 184, 1) 36.56%, rgba(3, 130, 171, 1) 44.29%, rgba(18, 67, 126, 1) 70.64%, rgba(22, 24, 47, 1) 90% )', 
}}>  
              <img className='block md:w-[70%] md:mx-auto  xl:w-auto xl:hidden' src='Image Files/SVG Files/Asset 6.svg' />

      <div className='xl:absolute right-0 top-0 mt-2 md:mt-0 w-full xl:w-[55%] px-6 py-6 z-10 bg-black  bg-opacity-20' style={{ backgroundColor: '' }}>
         <div className=''>
          <p className='text-white text-xl lg:text-xl '> HALISY Edcosystem Project </p>
          <span className='lg:text-[35px] text-2xl text-white  '>Funding Options</span>
          </div>
          <div className='border-t-[1px] lg:text-[18px]  text-white border-b-[1px] mt-3 py-3'>
               <p>
               In today’s super-fast advancement in technological
deployment, particularly in the education sector,
the cost of a functional, inclusive and sustainable
EdTech infrastructure and maintenance in schools
and learning centres, both private and public
presents a great deal of financial burden all over
the world, and for that matter Africa is always at the worst
end. 
<br></br><br></br>
The PROBLEM is, the initial investment for hardware, devices, software and educator training is
particularly not as expensive comparing to the cost
for maintenance of the infrastructure to ensure
sustainability and extended life-span.<br></br><br></br>
The good NEWS is, HALISY Edcosystem’s management, experts in education and technology
projects development have critically considered
and evaluated all these financial challenges
facing schools’ management in their efforts to
deploy a sustainable technologies in their
institutions.
To make our project objectives a reality, we have
designed a three flexible funding options to
support your dream of bringing quality education
through technology to your school, and access to
a computer by every child. 
                 </p>
                
          </div>
      </div>

      <div className='absolute xl:block hidden z-10 w-[35%] left-10 top-10'>
       <img src='Image Files/SVG Files/Asset 6.svg' />
      </div>
   </div>
   </div>
   
   

   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
   <div     className="bg-white rounded overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition-transform transform hover:scale-105 hover:shadow-[0_8px_20px_rgba(0,0,0,0.3)] opacity-90 hover:opacity-100  hover:bg-gray-100 duration-300">
           <div className='relative'>
            <img className="w-full  h-[60px]" src='Image Files/Asset 1.png' />
            <div className="absolute inset-0 flex justify-center items-center">
    <span className="text-white text-2xl">Ownership Purchase</span>
  </div>
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2"></div>
              <p className="text-gray-700 text-base  mb-3">
              This option is the regular direct
procurement process, budget is
determined based on project
scope and selected systems,
school management makes
payments per agreed terms,
devices are delivered and
installed, configured and
handed over. A monthly support
and maintenance budget is
required. Installment payment
plan request can be considered
for up to a maximum period of
twelve (12) months. 
              </p>
             
            </div>
          </div>
          <div     className="bg-white rounded overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition-transform transform hover:scale-105 hover:shadow-[0_8px_20px_rgba(0,0,0,0.3)] opacity-90 hover:opacity-100  hover:bg-gray-100 duration-300">
          <div className='relative'>
            <img className="w-full h-[60px]" src='Image Files/Asset 1.png ' />
            <div className="absolute inset-0 flex justify-center items-center">
    <span className="text-white text-2xl">Hire Purchase/Leasing</span>
  </div>
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2"></div>
              <p className="text-gray-700 text-base  mb-3">
              Hire purchase option is funded
by selected partner banks, and
funding duration can be from
twelve (12) months up to sixty
(60) months, depending on
budget and terms of engagement. This includes all required
equipment, devices, software,
user training and support/
maintenance. 
              </p>
             
            </div>
          </div>

          <div     className="bg-white rounded overflow-hidden shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition-transform transform hover:scale-105 hover:shadow-[0_8px_20px_rgba(0,0,0,0.3)] opacity-90 hover:opacity-100  hover:bg-gray-100 duration-300">
           <div className='relative'>
            <img className="w-full  h-[60px]" src='Image Files/Asset 1.png' />
            <div className="absolute inset-0 flex justify-center items-center">
            <span className="text-white text-2xl">
            iFaaS Subscription
            </span>
            </div>
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2"></div>
              <p className="text-gray-700 text-base  mb-3">
              iFaaS is an Infrastructure as a
Service, this option requires the
school to pay initial commitment fee of 10% of total project
value. HALISY Edcosystem and
partner banks will fund the
remaining 90% of the project
budget.
<br></br>
<br></br>
The school and parents then
will levy school fees at an
agreed amount every semester
or term as students facility user
fee. 
              </p>
             
            </div>
          </div>
   <div>
    
 
   </div>
   </div>
   </div>
   </div>
   
   
   </div>

   
   
    {/* Our Team */}
   <div ref={ourTeamRef} className='max-w-screen-2xl bg- mx-auto px-4 mt-[80px] xl:px-20'>
   <p className='w-full -mt-1 lg:text-[37px] text-3xl '> Our Key project Management Team</p>
   <div className='h-[18px] mt-[5px] border-4 border-x-0 border-t-0 border-b-[#27348B] w-[40px]'></div>
      <div className='flex mt-[15px]  flex-col-reverse xl:flex-row-reverse   gap-4'>
    
      <div className='w-full  xl:w-[50%] relative'>
      <img className='w-full h-full md:hidden' src='/Image Files/0.jpg' />
  <img className='w-full hidden md:block h-full' src='Image Files/Key Team Profile/Asset 20e.jpeg' />

  <div className='absolute top-10 w-[89%] z-0 bg-[#1D71B8] bg-opacity-75' style={{ backgroundColor: '' }}>
  
    <div className='relative hidden md:block z-10 p-4 pl-[24px] text-white'>
      <p className='text-3xl  '>Our Vision</p>
      <p className='mt-2 text-lg'>To attain the recognition and trust as providers of sustainable and inclusive teaching
         and learning technologies for the education communities across the African continent...
      </p>

      <p className='text-3xl mt-4 '>Our Mission</p>
      <p className='mt-2 text-lg'>Providing educational institutions with realistic digital teaching and learning technologies that leverage computer
hardware, AI devices and software/application platforms, which are sustainable, inclusive and affordable in Ghana and across Africa. Ensuring  HALISY Edcosystem becomes a household name within the education community  by the year 2030.
      </p>
    </div>
  </div>




  <div className='absolute hidden bottom-10 w-[] z-0 md:flex px-2 flex-col gap-2 ' >
  <div className='font-bold text-lg px-4 text-white'>Our Culture</div>
<div className='flex flex-row gap-2 '>
<div className='relative z-10 p-4 bg-[#1D71B8] text-white bg-opacity-75' >
  <p className='font-bold '>Solution Delivery </p>
  <p className='mt-2 text-sm'>Our firm belief in continuous growth,
learning and value for commitments
make us deliver solutions that meet
actual business needs and solve
problems, not just technnological
advancements.
  </p>

 
</div>

<div className='relative z-10 p-4 bg-[#1D71B8] text-white bg-opacity-75' >
  <p className='font-bold '>Being Humane </p>
  <p className='mt-2 text-sm'>
  HALISY fosters a culture of engagement and trust with all our dealings
with; clients, vendors and employees. We laugh, argue, toil, play and
succeed as one big family
  </p>

 
</div>

<div className='relative z-10 p-4 bg-[#1D71B8] text-white bg-opacity-75' >
  <p className='font-bold '>Live, Breathe Technology</p>
  <p className='mt-2 text-sm'>
  Our hunger for technology innovation
and trends in Education Technologies
topped with extensive industry
expertise makes our footing firm at
where we stand. We do right on the
first attempt
  </p>

 
</div>
</div>






  </div>


</div>

     
      <div className='flex flex-col md:w-full xl:w-[50%] pt-0  pb-0 justify-center '>
       
      
        <p className='mt-2 text-[22px]'> 
HALISY Edcosystem project is designed, developed
and managed by a team made up of seasoned, incisive academia and technology experts; we engage education stakeholders and technology consultants who supervise all projects to expected deliverables.
Our core management team members have combined industry experiences spanning over twenty-five (25) years in systems design and development, national scale technology projects implementation and matured service quality support. We do not sell technology solutions to our clientele and get vanished, we partner for a life long cooperation.
We hire dedicated and committed technical and operational staff members who are thirsty for success.</p>
     
      
       <div className='flex flex-row flex-wrap justify-center  md:flex-nowrap gap-4 mt-4'>
        <div className='relative ' >
        <img src='Image Files/Key Team Profile/Asset 9 - Mrs Abigail MO Tetteh.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] h- p-2'>
          <p className='font-[500] text-sm '>Mrs. Abigail M O Tetteh, Ph.D.</p>
          <p className='text-sm'> Executive Director</p>
          <div ref={dropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={toggleDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs '>
              Abigail M O Tetteh is an educationist with experience in Instructional Technology, TVET, teacher education, international education, and ICT for education development. She holds a PhD in Educational Leadership with specialisation in Instructional Technol- ogy.
              </p>
            </div>

)}
        </div>

        
        <div className='relative'>
        <img src='Image Files/Key Team Profile/Asset 11 - Philip Engmann.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] p-2'>
          <p className='font-[500] text-sm '>Ing. Dr. Philip Engmann</p>
          <p className='text-sm'> Director - Sys. Architecture Eng.
</p>
<div ref={philipDropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={togglePhilipDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isPhilipDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isPhilipDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs'>
              Philip is a seasoned telecom engineer, a service quality master, with a career experience spanning over 20 years, playing leading roles in large organisations, spearhead- ing national interest broadband migration campaigns.
              </p>
            </div>

)}
          

      
       

        </div>
        <div className='relative'>
        <img src='Image Files/Key Team Profile/Asset 12 Papa Arkhurst.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] p-2'>
          <p className='font-[500] text-sm '>Papa Arkhurst Dankwah</p>
          <p className='text-sm'> Sys. Implementation Specialist</p>
          <div ref={papaDropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={togglePapaDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isPapaDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isPapaDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs'>
              Papa is a seasoned technology professional, with over 12yrs experience in GoG’s large-scale systems deployment. A free-style personality, an avid skills & compe- tence development advocate.
               </p>
            </div>

)}

       
        </div>

        </div>
        <div className='flex mt-4 flex-row flex-wrap justify-center md:flex-nowrap gap-4'>
        <div className='relative'>
        <img src='Image Files/Key Team Profile/Asset 13 - Jim Garnish.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] p-2'>
          <p className='font-[500] text-sm '>James Alexander Garnish</p>
          <p className='text-sm'>Snr. Business Strategist </p>
          <div ref={jamesDropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={toggleJamesDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isJamesDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isJamesDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs'>
              Jim is an economist with over twenty (20) years in business management expertise, a soft-spoken, easy-going, but a firm taskmaster. A practical, all hands-on deck individual, with critical attention to details.
              </p>
            </div>

)}
      
       
        </div>
        <div className='relative'>
        <img src='Image Files/Key Team Profile/Asset 14 - Dr. Nefertiti.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] p-2'>
          <p className='font-[500] text-sm '>Nefertiti A. Puplampu Ph.D.</p>
          <p className='text-sm'> Planning & QA Consultant.</p>

          <div ref={nefertitiDropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={toggleNefertitiDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isNefertitiDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isNefertitiDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs'>
              Dr. N. Puplampu is a Learning Science Specialist, an Education Technology and Curriculum Consultant. She is a passionate advocate for responsible and inclusive AI adoption in schools in Ghana and across Africa.

              </p>
            </div>

)}
       

        </div>
        <div className='relative'>
        <img className='' src='Image Files/Key Team Profile/Asset 15- Kofi Martin.png' />
        <div className='mt-1 bg-black bg-opacity-[9%] p-2'>
          <p className='font-[500] text-sm '>Evans Kofi Martin</p>
          <p className='text-sm'> Chief Technical Officer</p>

          <div ref={evansDropdownRef} className='flex mt-1 flex-row items-center justify-between' onClick={toggleEvansDropdown}>
          <p className='text-xs text-blue-500 filter-none'>Profile Summary  </p>
          {isEvansDropdownOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            
            ) : (
             
                <svg xmlns="http://www.w3.org/2000/svg" className='w-3 h-3' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
           
            )}
             
               </div>

            
        </div>
     {/* Dropdown content */}
     {isEvansDropdownOpen && (
        <div className="absolute   w-full p-2 bg-white  bg-opacity-[85%] backdrop-filter backdrop-blur-sm z-10">
              <p className='text-xs'>
              A Micro Computer Systems Technologist, with over 25yrs expertise in research, design and development, Kofi approaches all projects with systematic planning, module piloting, and fine-tuning of methodologies.

              </p>
            </div>

)}
      

        </div>
        </div>

     
       
      </div>
      </div>
   </div>


      {/* Contact form and map section */}
      <div ref={formRef} className='max-w-screen-2xl bg- mx-auto px-4 mt-[60px] xl:px-20'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          {/* Contact Form */}
          <div>
            <p className='text-3xl'>Send Us A Message</p>
            <div className='w-[40px] border border-b-[3px] border-b-blue-900 mt-2'></div>
            <form onSubmit={handleContactSubmit} className='mt-4'>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <input
                    name="fname"
                    className="contact_form_detail block w-full pl-[15px] p-2 border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="First Name"
                    value={formData.fname}
                    onChange={handleInputChange}
                   
                  />
                </div>
                <div>
                  <input
                    name="lname"
                    className="contact_form_detail block w-full pl-[15px] p-2 border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="Last Name"
                    value={formData.lname}
                  onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    name="phone"
                    className="contact_form_detail block w-full p-2 pl-[15px] border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phone}
                  onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    name="email"
                    className="contact_form_detail block w-full p-2 pl-[15px] border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    name="company"
                    className="contact_form_detail block w-full p-2 pl-[15px] border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="Company Name"
                    value={formData.company}
                  onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    name="subject"
                    className="contact_form_detail block w-full p-2 pl-[15px] border-solid border hover:border-blue-900 text-[#777777] border-[#cccccc]"
                    required
                    type="text"
                    placeholder="Subject"
                    value={formData.subject}
                  onChange={handleInputChange}
                  />
                </div>
                <div className="col-span-1 md:col-span-2">
                  <textarea
                    name="message"
                    className="contact_form_detail1 block w-full p-2 pl-[15px] border-solid border h-[125px] text-[#777777] hover:border-blue-900 border-[#cccccc]"
                    required
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <div className="">
                    <input
                      name="send"
                      className="submit_btn px-[18px] cursor-pointer py-[11px] rounded-none bg-[#27348B] text-white text-lg font-medium hover:bg-blue-950"
                      type="submit"
                      value="SEND MESSAGE"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Map */}
          <div className='map'>
        
            <iframe
             src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d413.0174239463823!2d-0.20868115376933882!3d5.65901649548385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9c124ced1bb3%3A0xb1afec922197630f!2sThird%20St.%20K.%20Asante%20Ave%2C%20Accra!5e0!3m2!1sen!2sgh!4v1726670586647!5m2!1sen!2sgh'
             width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>


    {/* Footer */}   
<div className='bg-[#27348B] bg-opacity-25  w-full text-[#27348B] mt-10 lg:mt-[60px]'>
      <footer className='max-w-screen-2xl mx-auto px-4 xl:px-20 mt-10 lg:mt-[60px]' >
      <div class=" mx-auto py-[60px] ">
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-[200px_310px_200px_1fr_1fr] gap-6">
   
    <div className='col-span-1 '>
      <div class="footer_title">
        <h1 class="text-xl font-bold">HALISY Edcosystem</h1>
      </div>
      <ul class="quick_links mt-5 space-y-3">
        <li><button onClick={scrollToTop}  class="hover:underline font-medium  ">Home</button></li>
        <li><button onClick={scrollToAboutSection} class="hover:underline font-medium">About</button></li>
        
        <li><a  class="hover:underline font-medium">Team</a></li>
        <li><button onClick={scrollToForm}  class="hover:underline font-medium">Contact Us</button></li>
      </ul>
    </div>

  
    <div>
      <div class="footer_title">
        <h1 class="text-xl font-bold">Edcosystem Platform</h1>
      </div>
      <ul class="quick_links mt-5 space-y-3">
     
        <li><a href="" class="hover:underline font-medium">J100CODERS Social Club </a></li>
        <li><a href="" class="hover:underline font-medium">RCRooom - Remote Classroom Access </a></li>
        <li><a href="" class="hover:underline font-medium">School Time and Attendance Programme</a></li>
      
    
    
        
        
      </ul>
    </div>

    <div>
      <div class="footer_title">
        <h1 class="text-xl font-bold">Solutions</h1>
      </div>
      <ul class="quick_links mt-5 space-y-3 font-medium">
        <li><a href="" class="hover:underline ">Pre-school</a></li>
        <li><a href="" class="hover:underline">Primary/JHS</a></li>
        <li><a href="" class="hover:underline"></a>Senior High School</li>
        <li><a href="" class="hover:underline">Universities/Colleges</a></li>
        <li><a href="" class="hover:underline">HR Training Centers</a></li>
      </ul>
    </div>

   
   <div>
   <h1 class="text-xl font-bold">Contact Us</h1>
      <p className='font-medium mt-5 text-[16px]'>No. 4 Third Asante Lane,<br></br>
      WestLands, Accra<br></br>
      P. O. Box AT55, <br></br>
      Achimota, Accra, Ghana<br></br>
      WApp/Tel: +233 20 158 5353<br></br>
      Telephone:     <span>+233 59 888 8880</span>  <br></br>
     
      Email: infomail@halisy.com
   
         
      
      </p>
   </div>
   

   
    <div>
      <div class="footer_title">
        <h1 class="text-xl font-bold">Newsletter</h1>
      </div>
      <div class="news_letter mt-5">
        <p class="mb-4 font-medium">Subscribe to our newsletter to get the latest News and offers..</p>
        <form  onSubmit={handleSubmit} >
          <div class="flex items-center">
            <input type="text" value={email}
              onChange={(e) => setEmail(e.target.value)} className=" w-full px-3 py-2 border  border-gray-300 rounded text-[#777777] rounded-r-none  focus:outline-none focus:border-blue-500" required placeholder="Email Address"/>
            <button  class="border border-l-0 py-3 px-3.5 bg-[#27348B] rounded rounded-l-none flex items-center justify-center  hover:bg-blue-950" type="submit">
              <span></span>
            <svg xmlns="http://www.w3.org/2000/svg" className=' bg-none fill-white h-4 w-4' viewBox="0 0 512 512">{/*Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}<path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

      </footer>
      </div >

      <div className='w-full bg-[#27348B] text-black border-t-2 border-white'>
      <div class=" max-w-screen-2xl mx-auto px-4 xl:px-20 py-3.5" >
    <div class="max-w-screen-2xl mx-auto ">
        <div class="flex flex-col md:flex-row justify-between">
          
            <div class="mb-4 md:mb-0">
            <p className="text-white text-sm">
  © {new Date().getFullYear() > 2022 ? `2022 - ${new Date().getFullYear()}` : '2022'} Copyright 
  <a href="#" className="ml-1 text-sm text-white">HALISY Edcosystem - a CodLogic SE Project</a>. All Rights Reserved.
</p>

            </div>
         
            <div>
                <p class="text-white">
                    <button onClick={openModal} className="text-white text-sm hover:underline">Terms and Conditions</button>
                    &nbsp; | &nbsp; 
                    <button onClick={PPopenModal} className="text-white text-sm hover:underline">Privacy Policy</button>
                </p>
            </div>
        </div>
    </div>
</div>


      </div>
        {/* Whatsapp button */}
      <a
        href="https://wa.me/233201585353"
        className='fixed md:w-[50px] md:h-[50px] md:bottom-[40px] md:right-[47px] border-white border  w-[40px] h-[40px]  border-solid bottom-[20px] right-[10px] bg-[#25d366] stroke-white text-white rounded flex items-center justify-center   text-center text-[30px] z-[30] '
      
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className='w-full flex justify-center' >
         <svg xmlns="http://www.w3.org/2000/svg" className='fill-white w-[18.86px] h-[23px] md:w-[25.72px] md:h-[31px]  ' viewBox="0 0 448 512">{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc */}<path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
         </span></a>

         {showScrollTop && (
       <button type='button'
       className={`fixed bottom-[80px] shadow-xl md:right-[47px] md:bottom-[110px] w-[40px] md:w-[50px] md:h-[50px] h-[40px] right-[10px]  border-white border-solid border p-0 bg-[#27348B] text-white rounded  hover:bg-blue-700  flex items-center justify-center  
      `}
      
       onClick={scrollToTop}
     >
       <svg xmlns="http://www.w3.org/2000/svg" className="fill-white w-[13px] md:w-[18px]" viewBox="0 0 384 512">
         <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/>
       </svg>
     </button>
     
      )}
      {/* T&Cs Modal */}
      {isModalOpen && (
        <div  onClick={closeModal} className="fixed  inset-0 bg-black bg-opacity-50 z-[30] flex items-center  justify-center p-4 overflow-y-auto">
          <div className="bg-white z-[70] shadow-xl max-w-3xl w-full mt-20 ">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-2xl font-semibold">Terms and Conditions</h2>
              <button
                onClick={closeModal}
                className="text-red-500 hover:text-red-700 focus:outline-none"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-6 max-h-[40vh] md:max-h-[60vh] overflow-y-auto">
              <h3 className="text-xl font-semibold mb-4">IMPORTANT:</h3>
              <p className="mb-4">
              Please read the following before accessing and using this Site.<br></br><br></br>
Thank you for visiting <span className='text-blue-700'>https://www.halisy.com</span> we hope that you will find this website ("Site") helpful and informative. The following are the terms and conditions ("Terms") that apply to and govern your use of this Site. If you do not agree with any terms or conditions herein, or have any questions, before proceeding contact us directly at legal@halisy.com and we will be pleased to assist you. By choosing to access and use this site, you are expressly agreeing to be legally bound by these terms.
                             </p>

              <h3 className="text-xl font-semibold mb-4">Exclusive Terms:</h3>
              <p className="mb-4">
              Please note that these Terms exclusively govern your access and use of this Site and do not alter or affect the terms and conditions of any other agreement that you may have or enter into with any HALISY Edcosystem entities for any given product or service. The latter agreement will continue to govern that specific legal relationship with HALISY Edcosystem.
                     </p>

              <h3 className="text-xl font-semibold mb-4">Changes to Site:</h3>
              <p className="mb-4">
              In order to improve our service to you, we reserve the right to make changes in the access, operation, and content of this Site at any time without notice. We also reserve the right to make changes in the Terms and Conditions and the Privacy Statements, so please check before each use for changes.
                  </p>

              <h3 className="text-xl font-semibold mb-4">Copyright Notice:</h3>
              <p className="mb-4">
              You acknowledge that, unless otherwise specifically designated, HALISY Edcosystem is the sole owner of all intellectual property rights to this Site and its contents, including but not limited to copyright protection for all content, including the design, layout, graphics, photos, format, codes, scripts, links and other visual elements of the Site. All ownership rights pertaining to the information and materials contained on this Site, including but not limited to the HALISY Edcosystem name, logos, trademark, graphics, photos, images, audio, video, messages, files and content, are owned or have been duly licensed by HALISY Edcosystem, with all rights reserved. The Site pages and content may be electronically copied or printed in hard copy for internal informational, personal or non-commercial use only, provided that no modifications are made, it is not published in any media, and on each page of ever internal copy, HALISY Edcosystem is acknowledged as the source with a copyright symbol. You are not granted any license to use the content. Any use other than that set
forth above, including the non-internal reproduction, modification, copying, display or use of any copyright-protected HALISY Edcosystem or other logo, graphic, photo or image, without the prior written permission of HALISY Edcosystem, is strictly prohibited and will be enforced to the full extent of the law. Except for data and information submitted pursuant to an existing agreement with HALISY Edcosystem, any ideas, material, or content submitted by any user on or through this Site (including by e-mail) is, to the extent you own the intellectual property rights, the sole property of HALISY Edcosystem, and HALISY Edcosystem has no obligation of confidentiality nor are we responsible for any claims of infringement or misappropriation that may result from any submission. Personal information submitted will be treated in accordance with our <span className='text-blue-700 hover:underline cursor pointer'>Privacy Policy</span>. HALISY Edcosystem makes no guarantee, representation or warranty that the content of this Site or its use will not infringe upon the intellectual property right of any person, nor that the use, transfer, reproduction, distribution, transmission, or storage of any Site content outside Ghana is in compliance with the laws of any other governmental authority or jurisdiction which may be applicable.

                 </p>

              <h3 className="text-xl font-semibold mb-4">No Warranties Provided:
</h3>
              <p className="mb-4">
              The information on this Site is provided for general information purposes only. While HALISY Edcosystem strives to provide timely, accurate and complete information, you may discover some inadvertent typographical, technical, factual, or other errors or omissions in the information provided. Please bring them to our attention at <span className='text-blue-700'>infomail@halisy.com </span>Since such mistakes may occur, HALISY Edcosystem makes no guarantees, warranties or representations, express or implied, concerning the security, timeliness, relevancy, sufficiency, accuracy, reliability, fitness for any particular purpose, title, non-infringement or completeness of any data, information, or services furnished to you on or through this site, or concerning the information you provide to us. We are providing the information and other content contained herein on an "as is, where is, as available" basis, and all warranties (expressed or implied) are disclaimed, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
              

               
              </p>
              <h3 className="text-xl font-semibold mb-4">
              User Conduct:
</h3>
              <p className="mb-4">
              For the convenience of our users, some areas on this Site may allow for interactive communication, some of which may be conducted in "real time". You agree not to post any content which is or may be, in whole or in part: false, unlawful, criminal, fraudulent, misleading, infringing, libellous, slanderous, defamatory, profane, racist, sexist, threatening, inflammatory, obscene, invasive of personal or privacy rights, advertising, promoting or soliciting for commercial purposes, containing any known or unknown viruses or bugs, or which is otherwise harmful, offensive, inappropriate, or unprofessional. HALISY Edcosystem has no obligation to monitor, screen, filter or edit any such information, communication, or content that is directly posted by or shared by
users on the Site, however we may at our discretion from time to time monitor use of the Site, and we reserve the right to delete or edit any objectionable content posted or submitted by authorised users, terminate the user's access to the Site, deny future access, or take any other action we deem appropriate under the circumstances. Please report any such violations to us
at <span className='text-blue-700'> legal@jhalisy.com </span> HALISY Edcosystem will handle any personally identifiable data or information posted by you in accordance with our Privacy Policy, however, any third party liability or usage which may arise from your posting will be solely at your own risk. Information obtained through monitoring our Site may also be used for duly authorised law enforcement investigations. We also reserve the right to identify and investigate inquiries to the extent required to comply with applicable laws and regulations. HALISY Edcosystem will have no liability for any content posted by users on any Site.
              </p>

              <h3 className="text-xl font-semibold mb-4">
              Site Security:
</h3>
<p className="mb-4">
So that this Site remains to the extent possible open and available to all users, we have mechanisms in place to identify unauthorised attempts to gain access to Secured Sites, upload or change information or any image, inject any harmful elements, or otherwise cause damage. Regardless, even though we strive to keep these mechanisms up to date, due to the technical uncertainties, we are unable to make any representation or warranty that the content, information, or links in this Site are secure and free of data-processing errors, technical glitches, operational problems, interruptions, errors, bugs, worms, viruses, unauthorised third-party access, or any other harmful elements. You remain solely responsible for the security and protection of your systems when using this site, unauthorised attempts to upload, insert, or change any information or image on this site or cause harm are strictly prohibited and may be punishable to the full extent of the law, including the Ghana Critical Information
Infrastructure Protection <span className='font-[500]'>(The Cybersecurity Act, 2020)</span> and the International Information Infrastructure Protection Act.
</p>

<h3 className="text-xl font-semibold mb-4">
Outside Links:
</h3>
<p className='mb-4'>
For your information or convenience, this Site may contain direct links or hyperlinks to websites that are maintained by third parties. When clicking on such a link, you are leaving our Site. Therefore, HALISY Edcosystem has no control over, does not endorse, and is not liable for, the quality, operation, reliability, compliance with laws, ethics, business practices, nor content of such third-party websites, or for any product or service advertised or provided therein. Any transactions with such third parties, including provision of your personal information, are strictly at your own risk. We expressly disclaim all liability arising from your access of and for the content contained within or through any such third-party website.
</p>

            </div>
           
          </div>
        </div>
      )}
      {/* Privacy Policy Modal */}
      {isPrivacyPoModalOpen && (
         <div   onClick={PPcloseModal} className="fixed inset-0 bg-black bg-opacity-50 z-[30] flex items-center  justify-center p-4 overflow-y-auto">
         <div className="bg-white z-[70] shadow-xl max-w-3xl w-full mt-20 ">
           <div className="flex justify-between items-center p-6 border-b">
             <h2 className="text-2xl font-semibold">Privacy Policy</h2>
             <button
               onClick={PPcloseModal}
               className="text-red-500 hover:text-red-700 focus:outline-none"
             >
               <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
               </svg>
             </button>
           </div>
           <div className="p-6 max-h-[42vh] md:max-h-[60vh] overflow-y-auto">
             <h3 className="text-xl font-semibold mb-4">Introduction</h3>
             <p className="mb-4">
             This page sets out the privacy policy of HALISY Edcosystem, hereafter ("We", "Us" and "Our") <span className='text-blue-700'>https://www.halisy.com</span> explains how We use your personal information (meaning any information about you which is personally identifiable such as your name, address, telephone number or email address and is generally referred to throughout this policy as "Your Information") This privacy policy does not apply to the use of personal information which you provide to any third party website which We may be linked to. You should always check that you are happy with such websites' privacy policies before you provide personal information to them.
                           </p>

             <h3 className="text-xl font-semibold mb-4">Who are we?</h3>
             <p className="mb-4">
             HALISY Edcosystem is a technology solution brand owned by CodLogic Software Engineering Limited, a commercial business entity registered as company limited by shares in the Republic of Ghana, under the companies act, 1963 (Act 179) with registration number CS239142013 with its registered office address at No. 1 Kpetenplebi Link, Dzorwulu Accra, P. O. Box AT55, Achimota Accra, Ghana – West Africa
                     </p>

             <h3 className="text-xl font-semibold mb-4">Information We collect about you</h3>
             <p className="mb-4">
             <ul class="list-disc list-outside ml-8 space-y-2">
  <li class="">We may collect and process the following data about you:
If you are an individual representing an education institution or other expert involved in the delivery of HALISY Edcosystem, we may collect and store Your Information that is provided to Us during the term of your involvement with HALISY Edcosystem. We may collect and store information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website.</li>
  <li class="">If you contact us, we may keep a record of that correspondence.</li>
  <li class="">We may also ask you to complete surveys that we use for research
purposes.</li>
</ul>
                  </p>

             <h3 className="text-xl font-semibold mb-4">The way We use Your Information</h3>
            

             <p className="mb-4">
                <ul class="list-disc list-outside ml-8 space-y-2">
  <li class=""> We use Your Information for the purposes of providing feedback and to answer queries, including but not limited to: <br></br>
Ensure that content from our Website is presented in the most effective manner for you and your computer. </li>
  <li class="">Carry out our obligations arising from any contracts entered into between you and us.</li>
  <li class="">Notify you about changes to Our products and services.</li>
</ul>

              
             </p>
             <h3 className="text-xl font-semibold mb-4">
             Will We share Your Information with third parties?
</h3>
             <p className="mb-4">
             We may also disclose Your Information to third parties if We are under a duty to disclose or share this in order to comply with any legal obligation, or in order to enforce or apply Our Website terms and conditions and other agreements; or to protect the rights, property, or safety of HALISY Edcosystem, our users, or others. We may also share your information with our suppliers and subcontractors to provide HALISY Edcosystem services to you. If we do, we will remain responsible for the processing of your information and will ensure that our suppliers and subcontractors comply with the terms of this policy.
                     </p>

             <h3 className="text-xl font-semibold mb-4">
             How do We protect Your Information?
</h3>
<p className="mb-4">
Unfortunately, the transmission of information via the internet is not completely secure. However, once we have received Your Information, we will use appropriate procedures to try to prevent unauthorised access. Where We have given you (or where you have chosen) a password which enables you to access certain parts of Our Website, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
</p>

<h3 className="text-xl font-semibold mb-4">
Our use of Cookies
</h3>
<p className='mb-4'>
Cookies are small text files that are placed on your computer by websites you visit. We use cookies that are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website and cookies that collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don't collect information that identifies you. All information these cookies collect is aggregated and therefore anonymous. They are only used to improve how a website works. You may choose not to accept cookies by activating the setting on your browser, which allows you to refuse the setting of cookies. Unless you do this, cookies will be issued when you use our site, but you can delete them via your browser at any time. Please note that if you do not accept cookies, or decide to delete them, this may impact on your user experience while on the site.
</p>

<h3 className="text-xl font-semibold mb-4">Access to Your Information, correction and unsubscribing</h3>
<p className="mb-4">
Please contact Us if you have any questions about Our privacy policy or information We hold about you by email at <span className='text-blue-700'>infomail@halisy.com</span> or write to Us at P. O. Box AT55, Achimota Accra, Ghana – West Africa.<br></br><br></br>
You have the right to request a copy of the information that We hold about you. If you would like a copy of some or all of the information We hold about you, please contact Us at the contact details given above. We may make a small charge for this.<br></br><br></br>
We want to make sure that Your Information is accurate and up to date. You may ask Us to correct or remove information you think is inaccurate by contacting Us by email at <span className='text-blue-700'>infomail@halisy.com</span>
</p>

<h3 className="text-xl font-semibold mb-4">Changes to this privacy policy</h3>
<p className='mb-4'>
We are constantly looking for to new ways to improve this Website. We may amend this privacy policy from time to time. <span className='text-xs'>This privacy policy is dated June 2024.</span>
</p>


           </div>
          
         </div>
       </div>
      )}
    </div>
  );
}

export default App;
{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg> */}